<template>
  <div class="nav" :class="topFiexd ? 'nav_fixed' : ''">
    <div class="nav_main d-flex">
      <div class="public_width d-between">
        <h1 class="logo" @click="to_home()">
          <img
            class="img"
            src="@/assets/images/color_logo.png"
            alt="LOGO"
            v-if="topFiexd"
          />
          <img class="img" src="@/assets/images/logo.png" alt="LOGO" v-else />
        </h1>

        <div class="nav_menus d-flex">
          <div class="item" @click="to_home">首页</div>
          <div class="item">
            <el-dropdown trigger="click"
            >
              <span class="el-dropdown-link">
                提供服务
                <i class="icon iconfont icon-xl"></i>
              </span>

              <template #dropdown>
                <el-dropdown-menu>
                  <el-dropdown-item>
                    <a class="item" href="#ecology"> 生态构建 </a>
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <a class="item" href="#brand"> 品牌构建 </a>
                  </el-dropdown-item>

                  <el-dropdown-item>
                    <a class="item" href="#product"> 产品输出 </a>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </template>
            </el-dropdown>
          </div>

          <a class="item" href="#partner">合作伙伴</a>
          <a class="item" href="#about">关于我们</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { reactive, toRefs, defineComponent, onMounted, nextTick } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    active: String,
    sHeight: Number,
  },
  emits: ["get", "help"],
  setup(props) {
    const router = useRouter();

    let state = reactive({
      topFiexd: false,
    });

    onMounted(() => {
      nextTick(() => {
        window.addEventListener("scroll", () => {
          let scrollTop =
            window.pageYOffset ||
            document.documentElement.scrollTop ||
            document.body.scrollTop;

          state.topFiexd = scrollTop > props.sHeight;
        });
      });
    });
    const to_home = () => {
      router.push({
        path: "/",
      });
    };

    return {
      ...toRefs(state),
      to_home,
    };
  },
});
</script>

<style lang="less" scoped>
.nav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 86px;
  background: transparent;
  z-index: 999;

  .nav_main {
    align-items: center;
    height: 100%;
  }

  .logo {
    cursor: pointer;

    .img {
      width: 165px;
      height: 40px;
    }
  }

  .nav_menus {
    .item {
      display: block;
      margin-left: 80px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      cursor: pointer;
    }

    .el-dropdown-link {
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
    }
  }
}

.nav_fixed {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);

  .nav_menus {
    .item,
    .el-dropdown-link {
      color: #333;
    }
  }
}
</style>
