<template>
  <div class="home public_wrap">
    <topnav active="home" :sHeight="sHeight"></topnav>

    <div class="home_main">
      <div class="big_banner" ref="bannerRef">
        <el-carousel
          trigger="click"
          indicator-position="none"
          :initial-index="carouselIndex"
          @change="carouselChange"
          ref="carousel"
          height="690px"
        >
          <el-carousel-item v-for="(item, index) in 4" :key="index">
            <img
              class="img"
              :src="require('../assets/images/banner/' + (index + 1) + '.jpg')"
            />
          </el-carousel-item>
        </el-carousel>

        <div class="sort_items d-center">
          <div
            class="item"
            :class="carouselIndex == i ? 'act' : ''"
            @click="setActiveItem(i)"
            v-for="(v, i) in carouselList"
            :key="i"
          >
            <div class="txt">{{ v.name }}</div>
            <div class="line"></div>
          </div>
        </div>
      </div>

      <!-- 生态构建 -->
      <div class="ecology" id="ecology">
        <div class="public_width">
          <img class="top_tit_img" src="@/assets/images/zi-stgj.png" />

          <div class="items_main d-center">
            <div class="l_items">
              <div class="item d-center top_item">
                <img
                  class="img img1"
                  src="@/assets/images/ecology/icon-sq.png"
                />

                <div class="r_txt">
                  <div class="tit">社区</div>
                  <div class="txt">为企业提供技术交流分享平台</div>
                </div>
              </div>

              <div class="item d-center">
                <img
                  class="img img2"
                  src="@/assets/images/ecology/icon-kyyj.png"
                />

                <div class="r_txt">
                  <div class="tit">开源硬件</div>
                  <div class="txt">基于芯片搭建解决方案平台</div>
                </div>
              </div>
            </div>

            <img
              class="center_img"
              src="@/assets/images/ecology/tu-fwqyal.png"
            />

            <div class="r_items">
              <div class="item d-center top_item">
                <img
                  class="img img3"
                  src="@/assets/images/ecology/icon-sq-1.png"
                />

                <div class="r_txt">
                  <div class="tit">社群</div>
                  <div class="txt">协助企业构及维护微信/QQ社群</div>
                </div>
              </div>

              <div class="item d-center center_item">
                <img
                  class="img img4"
                  src="@/assets/images/ecology/icon-hdch.png"
                />

                <div class="r_txt">
                  <div class="tit">活动策划</div>
                  <div class="txt">组织线上线下座谈会议</div>
                </div>
              </div>

              <div class="item d-center">
                <img
                  class="img img5"
                  src="@/assets/images/ecology/icon-gcsst.png"
                />

                <div class="r_txt">
                  <div class="tit">工程师生态</div>
                  <div class="txt">为电子工程师提供研发相关服务</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 品牌构建 -->
      <div class="brand" id="brand">
        <div class="public_width">
          <img class="top_tit_img" src="@/assets/images/zi-ppgj.png" />

          <div class="top_items d-between">
            <div class="item d-center top_item">
              <img
                class="img"
                src="@/assets/images/brand/dicon-syszhcpdz.png"
              />

              <div class="r_txt">
                <div class="tit">私有数字化产品定制</div>
                <div class="txt">
                  为企业构建定制化软件应用平台，包括网站建设、APP、微信平台
                </div>
              </div>
            </div>

            <div class="item d-center r_item">
              <img
                class="img"
                src="@/assets/images/brand/dicon-hlwyxgj.png"
                style="width: 104px; height: 104px"
              />

              <div class="r_txt">
                <div class="tit">互联网营销工具</div>
                <div class="txt">
                  提供产品推广服务：营销工软件开发、搜索引擎优化、多媒体平台及社群的营销推广
                </div>
              </div>
            </div>
          </div>

          <div class="tab_main d-flex">
            <div class="l_tab_items">
              <div
                class="item"
                :class="tabIndex == index ? 'active' : ''"
                @click="choose_tab(index)"
                v-for="(item, index) in tabList"
                :key="index"
              >
                {{ item.name }}
              </div>
            </div>

            <div class="r_imgs_mian d-flex" v-if="tabIndex == 0">
              <div
                class="item"
                :class="lOver ? 'enter' : ''"
                @mouseenter="mouseenter(1)"
              >
                <div class="tit d-center">社区类</div>
                <img class="img" src="@/assets/images/brand/img/tu1.1.png" />
              </div>

              <div
                class="item r_item"
                :class="rOver ? 'enter' : ''"
                @mouseenter="mouseenter(2)"
              >
                <div class="tit d-center">电商类</div>
                <img class="img" src="@/assets/images/brand/img/tu1.2.png" />
              </div>
            </div>

            <div class="r_imgs_mian d-flex" v-if="tabIndex == 1">
              <div
                class="item"
                :class="lOver ? 'enter' : ''"
                @mouseenter="mouseenter(1)"
              >
                <div class="tit d-center">营销工具</div>
                <img class="img" src="@/assets/images/brand/img/tu2.1.png" />
              </div>

              <div
                class="item r_item"
                :class="rOver ? 'enter' : ''"
                @mouseenter="mouseenter(2)"
              >
                <div class="tit d-center">CRM</div>
                <img class="img" src="@/assets/images/brand/img/tu2.2.png" />
              </div>
            </div>

            <div class="r_imgs_mian d-flex" v-if="tabIndex == 2">
              <div
                class="item"
                :class="lOver ? 'enter' : ''"
                @mouseenter="mouseenter(1)"
              >
                <div class="tit d-center">办公管理</div>
                <img class="img" src="@/assets/images/brand/img/tu3.1.png" />
              </div>

              <div
                class="item r_item"
                :class="rOver ? 'enter' : ''"
                @mouseenter="mouseenter(2)"
              >
                <div class="tit d-center">大数据</div>
                <img class="img" src="@/assets/images/brand/img/tu3.2.png" />
              </div>
            </div>

            <div class="r_imgs_mian d-flex" v-if="tabIndex == 3">
              <div
                class="item"
                :class="lOver ? 'enter' : ''"
                @mouseenter="mouseenter(1)"
              >
                <div class="tit d-center">充电服务</div>
                <img class="img" src="@/assets/images/brand/img/tu4.1.png" />
              </div>

              <div
                class="item r_item"
                :class="rOver ? 'enter' : ''"
                @mouseenter="mouseenter(2)"
              >
                <div class="tit d-center">社区类</div>
                <img class="img" src="@/assets/images/brand/img/tu4.2.png" />
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 产品输出 -->
      <div class="product" id="product">
        <div class="public_width">
          <img class="top_tit_img" src="@/assets/images/zi-cpsc.png" />

          <div class="items_box d-center">
            <div class="item d-center">
              <div class="count">01</div>
              <img class="img" src="@/assets/images/product/icon-gylfw.png" />

              <div class="r_txt">
                <div class="tit">供应链服务</div>
                <div class="txt">
                  定制需求 → 解决方案设计 → 元器件订购 → 工厂生产服务
                </div>
              </div>
            </div>

            <div class="item d-center">
              <div class="count">02</div>
              <img
                class="img img2"
                src="@/assets/images/product/icon-fasjfw.png"
              />

              <div class="r_txt">
                <div class="tit">方案设计服务</div>
                <div class="txt">AI、物联网相关方向的解决方案</div>
              </div>
            </div>

            <div class="item d-center item3">
              <div class="count">03</div>
              <img
                class="img img3"
                src="@/assets/images/product/icon-gcsc.png"
              />

              <div class="r_txt">
                <div class="tit">工厂生产服务</div>
                <div class="txt">SMT贴片、模块化生产</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- 服务水平 -->
      <div class="serve_level" id="serve">
        <div class="public_width">
          <img class="top_tit_img" src="@/assets/images/zi-fwsp.png" />

          <div class="top_bgc"></div>

          <div class="items_box d-flex">
            <div class="item d-flex">
              <div class="r_txt">
                <div class="tit">研发制造能力</div>
                <div class="txt">
                  <span></span> 资深研发工程师 <br />
                  <span></span> 线下2千平米SMT加工 <br />
                  生产工厂
                </div>
              </div>

              <img class="img" src="@/assets/images/serve/tu-jgys.png" />
            </div>

            <div class="item d-flex">
              <div class="r_txt">
                <div class="tit">供应链优势</div>
                <div class="txt">
                  <span></span> 从产品研发到采购生产 <br />
                  测试全方位服务<br />
                  <span></span> 数字化，平台化，协同<br />
                  化系统管理
                </div>
              </div>

              <img class="img" src="@/assets/images/serve/tu-jstd.png" />
            </div>

            <div class="item d-flex">
              <div class="r_txt">
                <div class="tit">平台化产品输出</div>
                <div class="txt">
                  <span></span> 线上数字化平台工具推广 <br />
                  <span></span> 定制化软件应用平台
                </div>
              </div>

              <img class="img" src="@/assets/images/serve/tu-fwbz.png" />
            </div>
          </div>
        </div>
      </div>

      <!-- 合作伙伴 -->
      <div class="partner" id="partner">
        <div class="public_width">
          <img class="top_tit_img" src="@/assets/images/zi-hzhb.png" />

          <div class="items_box d-flex">
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/1.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/2.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/3.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/4.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/5.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/6.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/7.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/8.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/9.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/10.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/11.png" />
            </div>
            <div class="item d-center">
              <img class="img" src="@/assets/images/partner/12.png" />
            </div>
          </div>
        </div>
      </div>

      <!-- 关于我们 -->
      <div class="about" id="about">
        <div class="public_width">
          <img class="top_tit_img" src="@/assets/images/zi-gywm.png" />

          <div class="items_box d-flex">
            <img class="img" src="@/assets/images/tu-gywm.png" />

            <div class="r_txt">
              <div class="tit">公司理念：启承诺言、匠心服务</div>
              <div class="txt">
                南京启诺信息技术有限公司成立于2018年，总部坐落于南京江宁总部基地，致力于半导体原厂的生态构建、电子行业领域品牌的构建、AI视觉方案一站式服务。基于该目标，我们为海思、君正、新唐、瑞芯微等半导体原厂构建了社区、社群等其他生态系统；我们孵化出的品牌：易百纳，也在电子行业占据了一席之地，同时我们也逐步涉猎了其他行业领域的品牌构建，为他们开发出了一系列的软件应用平台，涉及船舶行业、电力行业、工业设计行业等等。我们的核心技术团队参与过各种类型的开发项目，经验丰富。我们秉承着公司的理念：启承诺言，匠心服务，希望能打造出更多知名品牌，为更多企业带去更优质的服务。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <foot />
  </div>
</template>

<script >
import topnav from "@/components/Nav.vue";
import foot from "@/components/Footer.vue";
import { reactive, toRefs, ref, onMounted, nextTick } from "vue"; // 父组件引入 provide

export default {
  name: "Home",
  components: {
    topnav,
    foot,
  },
  setup() {
    let state = reactive({
      carouselIndex: 0,
      carouselList: [
        {
          name: "服务总介",
        },
        {
          name: "生态构建",
        },
        {
          name: "品牌构建",
        },
        {
          name: "一站式服务",
        },
      ],
      tabIndex: 0,
      tabList: [
        {
          name: "网站建设",
        },
        {
          name: "小程序",
        },
        {
          name: "APP应用",
        },
        {
          name: "公众号",
        },
      ],
      lOver: true,
      rOver: false,
    });

    const bannerRef = ref(null);
    const sHeight = ref(0);
    const carousel = ref(null);

    onMounted(() => {
      nextTick(() => {
        let height = bannerRef.value.getBoundingClientRect().height;
        // console.log("home banner >>", height);
        sHeight.value = height;
      });
    });

    const choose_tab = (i) => {
      state.tabIndex = i;
      state.lOver = true;
      state.rOver = false;
    };

    const mouseenter = (i) => {
      if (i == 1) {
        state.lOver = true;
        state.rOver = false;
      } else {
        state.lOver = false;
        state.rOver = true;
      }
    };

    const carouselChange = (i) => {
      state.carouselIndex = i;
    };

    const setActiveItem = (i) => {
      carousel.value.setActiveItem(i);
    };

    return {
      ...toRefs(state),
      bannerRef,
      sHeight,
      choose_tab,
      mouseenter,
      carouselChange,
      setActiveItem,
      carousel,
    };
  },
};
</script>

<style lang="less" scoped>
.home {
  .home_main {
    .big_banner {
      position: relative;
      width: 100%;
      // height: 100vh;
      height: 690px;
      // background: url("../assets/images/banner1.png") no-repeat top center;

      .el-carousel__item {
        height: 100%;

        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }
      }

      .sort_items {
        position: absolute;
        bottom: 20px;
        left: 0;
        width: 100%;
        z-index: 111;

        .item {
          margin-left: 20px;
          opacity: 0.39;
          cursor: pointer;

          .txt {
            margin-bottom: 10px;
            width: 78px;
            height: 32px;
            line-height: 32px;
            font-size: 12px;
            font-weight: bold;
            color: #ffffff;
            text-align: center;
            border: 1px solid #ffffff;
          }

          .line {
            display: none;
            width: 78px;
            height: 2px;
            background-color: #fff;
          }
        }

        .act {
          opacity: 1;

          .line {
            display: block;
          }
        }

        // .item3 {

        // }
      }
    }

    .top_tit_img {
      display: block;
      margin: 0 auto;
      object-fit: contain;
    }

    // 生态构建
    .ecology {
      padding-top: 99px;
      height: 831px;
      background-color: #fff;

      .top_tit_img {
        margin-bottom: 45px;
        width: 480px;
        height: 41px;
      }

      .items_main {
        display: flex;
        justify-items: center;

        .item {
          // padding: 0 30px;
          width: 380px;
          height: 92px;
          background: rgba(255, 255, 255, 0.39);
          box-shadow: 0px 2px 8px rgba(16, 91, 178, 0.16);
          border-radius: 4px;
          cursor: pointer;

          .img {
            margin-right: 40px;
          }

          .r_txt {
            .tit {
              margin-bottom: 10px;
              font-size: 20px;
              font-weight: bold;
              line-height: 1;
              color: #333333;
            }
            .txt {
              font-size: 14px;
              font-weight: 400;
              line-height: 1;
              color: #666666;
            }
          }

          .img1 {
            width: 75px;
            height: 55px;
          }
          .img2 {
            width: 76px;
            height: 61px;
          }
          .img3 {
            width: 92px;
            height: 48px;
          }
          .img4 {
            width: 64px;
            height: 56px;
          }
          .img5 {
            width: 66px;
            height: 49px;
          }
        }

        .item:hover {
          background-color: #0058f0;
          transition: all 0.3s linear;

          .tit,
          .txt {
            color: #fff;
          }
        }

        .l_items {
          .top_item {
            position: relative;
            left: -59px;
            margin-bottom: 100px;
          }
        }

        .r_items {
          .center_item {
            position: relative;
            left: 44px;
            margin: 50px 0 57px;
          }
        }

        .center_img {
          margin-left: 20px;
          margin-right: 46px;
          width: 495px;
          height: 481px;
        }
      }
    }

    // 品牌构建
    .brand {
      padding-top: 100px;
      // background: rgba(229, 240, 255, 0.39);
      background-color: #e5f0ff;
      height: 925px;

      .top_tit_img {
        margin-bottom: 50px;
        width: 280px;
        height: 48px;
      }

      .top_items {
        .item {
          padding-left: 66px;
          padding-right: 48px;
          width: 659px;
          height: 206px;
          background: rgba(255, 255, 255, 1);
          box-shadow: 0px 0px 20px rgba(16, 91, 178, 0.1);
          border-radius: 4px;

          .img {
            margin-right: 165px;
            width: 120px;
            height: 120px;
          }

          .r_txt {
            .tit {
              margin-bottom: 25px;
              font-size: 18px;
              font-weight: bold;
              line-height: 1;
              color: #0058f0;
            }
            .txt {
              font-size: 16px;
              font-weight: 400;
              line-height: 27px;
              color: #333333;
            }
          }
        }

        .r_item {
          .img {
            margin-right: 110px;
          }
        }
      }

      .tab_main {
        margin-top: 75px;
        align-items: center;

        .l_tab_items {
          .item {
            margin-bottom: 20px;
            width: 130px;
            height: 56px;
            line-height: 56px;

            font-size: 18px;
            font-weight: bold;
            color: #0058f0;
            text-align: center;
            background: #fff;
            box-shadow: 0px 0px 20px rgba(16, 91, 178, 0.16);
            border-radius: 28px 0px 0px 28px;
            cursor: pointer;
          }

          .active {
            background-color: #0058f0;
            color: #fff;
          }
        }

        .r_imgs_mian {
          width: 100%;

          .item {
            position: relative;
            padding: 20px;
            width: 100%;
            height: 420px;
            background: rgba(255, 255, 255, 0.39);

            .tit {
              position: absolute;
              top: -40px;
              right: 20px;
              width: 128px;
              height: 40px;
              background: rgba(255, 255, 255, 0.39);
              border-radius: 12px 12px 0px 0px;

              font-size: 16px;
              font-weight: 500;
              color: #333333;
            }

            .img {
              width: 100%;
              height: 100%;
              opacity: 0.39;
            }
          }

          .r_item {
            margin-left: 4px;
          }

          .enter {
            background-color: #fff;

            .tit {
              background: #fff;
            }

            .img {
              opacity: 1;
            }
          }

          .lever {
            background: rgba(255, 255, 255, 0.39);

            .tit {
              background: rgba(255, 255, 255, 0.39);
            }

            .img {
              opacity: 0.39;
            }
          }
        }
      }
    }

    // 产品输出
    .product {
      padding-top: 100px;
      height: 561px;
      background-color: #fff;

      .top_tit_img {
        margin-bottom: 118px;
        width: 300px;
        height: 48px;
      }

      .items_box {
        width: 100%;
        justify-content: space-between;

        .item {
          position: relative;
          margin-right: 35px;
          padding: 0 20px 0 40px;
          min-width: 356px;
          max-width: 578px;

          height: 169px;
          background-color: #fff;
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
          border-radius: 103px;
          flex-shrink: 0;

          .img {
            margin-right: 20px;
            width: 71px;
            height: 69px;
          }

          .img2 {
            width: 55px;
            height: 65px;
          }

          .img3 {
            width: 66px;
            height: 71px;
          }

          .r_txt {
            .tit {
              margin-bottom: 29px;
              font-size: 18px;
              font-weight: bold;
              line-height: 1;
              color: #333333;
            }
            .txt {
              font-size: 16px;
              font-weight: 400;
              line-height: 1;
              color: #333333;
            }
          }

          .count {
            position: absolute;
            top: 0;
            left: 0;

            width: 52px;
            height: 52px;
            background: rgba(51, 51, 51);
            border-radius: 50%;

            font-size: 24px;
            font-weight: bold;
            line-height: 52px;
            color: #ffffff;
            text-align: center;
          }
        }

        :last-child {
          margin-right: 0;
        }

        .item:hover {
          background-color: #0058f0;
          transition: all 0.3s linear;

          .tit,
          .txt {
            color: #fff;
          }
        }
      }
    }

    // 服务水平
    .serve_level {
      position: relative;
      padding-top: 35px;
      height: 471px;
      background: #f7f9ff;

      .top_tit_img {
        position: relative;
        margin-bottom: 53px;
        width: 252px;
        height: 48px;
        z-index: 2;
      }

      .top_bgc {
        position: absolute;
        top: 0;
        left: 0;
        width: 1920px;
        height: 293px;
        background: linear-gradient(180deg, #5a96ff 0%, #004fe8 100%);
        z-index: 1;
      }

      .items_box {
        position: relative;
        justify-content: center;
        align-items: center;
        height: 280px;

        .item {
          position: relative;
          margin-right: 60px;
          width: 426px;
          height: 280px;

          background-color: #fff;
          box-shadow: 0px 0px 6px rgba(106, 131, 165, 0.16);
          border-radius: 8px;
          overflow: hidden;
          z-index: 3;
          flex-shrink: 0;
          cursor: pointer;

          .img {
            width: 206px;
            height: 100%;
            flex-shrink: 0;
          }

          .r_txt {
            padding: 84px 15px 0 50px;
            width: 100%;

            .tit {
              margin-bottom: 31px;
              font-size: 20px;
              font-weight: bold;
              line-height: 34px;
              color: #333333;
              white-space: nowrap;
            }

            .txt {
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              color: #333333;
              white-space: nowrap;

              span {
                position: relative;
                top: -2px;
                display: inline-block;
                width: 5px;
                height: 5px;
                background: #333333;
                border-radius: 50%;
              }
            }
          }

          .count {
            position: absolute;
            top: 0;
            left: 0;

            width: 52px;
            height: 52px;
            background: rgba(51, 51, 51);
            border-radius: 50%;

            font-size: 24px;
            font-weight: bold;
            line-height: 52px;
            color: #ffffff;
            text-align: center;
          }
        }

        .item:hover {
          transition: all 0.3s linear;
          position: relative;
          //   top: -13px;
          left: -20px;
          margin-right: 20px !important;
          width: 466px;
          height: 306px;

          .img {
            width: 225px;
          }

          .tit {
            margin-bottom: 29px;
            font-size: 24px;
            color: #0058f0;
          }

          .txt {
            font-size: 15px;
            line-height: 26px;
          }
        }
      }
    }

    // 合作伙伴
    .partner {
      padding-top: 120px;
      background-color: #fff;

      .top_tit_img {
        position: relative;
        margin-bottom: 25px;
        width: 392px;
        height: 48px;
      }

      .items_box {
        flex-wrap: wrap;
        width: 110%;
      }

      .item {
        margin: 0 20px 20px 0;
        width: 335px;
        height: 91px;
        background: rgba(255, 255, 255, 0.39);
        border: 1px solid #e5e5e5;
        border-radius: 2px;

        .img {
          width: 156px;
          height: 72px;
          object-fit: contain;
        }
      }
    }

    // 关于我们
    .about {
      padding: 77px 0 95px;
      background-color: #fff;

      .top_tit_img {
        position: relative;
        margin-bottom: 50px;
        width: 248px;
        height: 62px;
      }

      .items_box {
        .img {
          position: relative;
          top: -36px;
          width: 476px;
          height: 354px;
          object-fit: contain;
        }

        .r_txt {
          padding: 91px 72px 0;
          //   width: 924px;
          width: 100%;
          height: 318px;
          background: rgba(255, 255, 255, 0.39);
          box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);

          .tit {
            margin-bottom: 26px;
            font-size: 16px;
            font-weight: 600;
            line-height: 1;
            color: #333333;
          }

          .txt {
            font-size: 13px;
            font-weight: 400;
            line-height: 23px;
            color: #333333;
          }
        }
      }
    }
  }
}
</style>