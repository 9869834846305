<template>
  <div class="footer">
    <div class="public_width ft_link_wrap">
      <div class="ft_links d-flex">
        <div class="ft_friend">
          <div class="label">友情链接：</div>

          <a href="https://www.ebaina.com" target="_blank" rel="nofollow">
            易百纳技术社区
          </a>
          <a href="https://chip.ebaina.com" target="_blank" rel="nofollow">
            百纳芯城
          </a>
          <a href="https://www.wamuju.com" target="_blank" rel="nofollow">
            挖模具
          </a>
        </div>

        <div class="ft_friend">
          <div class="label">联系方式:</div>

          <p>手机：17714209983</p>
          <p>微信：yunfanganapp</p>
          <p>QQ：1289459644</p>
          <p>公司地址：南京市江宁经济开发区菲尼克斯路70号总部基地22栋一层</p>
        </div>
      </div>

      <div class="ft_code">
        <div class="ft_yun">
          <img
            src="https://ebaina.oss-cn-hangzhou.aliyuncs.com/direct/resource/202112/14/%E9%A3%9E%E4%B9%A620211216-175013.png"
          />
          <p>易百纳公众号</p>
        </div>

        <div class="ft_yun">
          <img src="../assets/images/wechat_kf.jpg" />

          <p>微信</p>
        </div>

        <div class="ft_yun">
          <img src="../assets/images/qq_kf.png" />
          <p>QQ</p>
        </div>
      </div>
    </div>

    <div class="ft_bt">
      Copyright &copy; 2009-2021 南京启诺信息技术有限公司版权所有.&nbsp;All
      Rights Reserved.&nbsp;苏ICP备14036084号-1
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  props: {
    active: String,
  },
  setup(props, ctx) {
    let name = reactive(props.active);
    const router = useRouter();

    let to_help = (v) => {
      router.push({
        path: "/help",
        query: {
          id: v,
        },
      });

      if (name == "help") {
        ctx.emit("get", { id: v });
      }

      window.scrollTo(100, 0);
    };

    return {
      name,
      to_help,
    };
  },
});
</script>

<style lang="less">
.footer {
  // position: absolute;
  // bottom: 0;
  // left: 0;
  // width: 100%;
  background: #333;

  .ft_link_wrap {
    display: flex;
    justify-content: space-between;
    height: 235px;
    padding: 28px 0 0;

    .ft_links {
      .ft_friend {
        margin-right: 114px;

        .label {
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          color: #999999;
        }

        a,
        p {
          display: block;
          margin-bottom: 20px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1;
          color: #ffffff;
        }
      }
    }

    .ft_code {
      display: flex;
      justify-content: flex-end;

      .ft_yun {
        margin-left: 50px;

        img {
          margin-bottom: 13px;
          height: 110px;
          width: 110px;
          border-radius: 10px;
          background: #fff;
        }

        p {
          font-size: 14px;
          font-weight: 400;
          line-height: 30px;
          color: #ededed;
          text-align: center;
        }
      }
    }
  }

  .ft_bt {
    height: 54px;
    line-height: 54px;
    background: rgba(70, 70, 70, 1);
    text-align: center;

    font-size: 12px;
    color: #bfbfbf;
  }
}
</style>
